import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  padding: 1rem 0 3rem;
  font-variant: all-petite-caps;
  align-items: center;
  // & h3 {
  //   font-size: 1.4rem;
  //   margin-right: 20px;
  //   color: darkgoldenrod;
  //   word-wrap: break-word;
  // }
`

const NavList = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding-inline-start: 0px;
`

const NavLink = styled(props => <Link {...props} />)`
  color: #bbb;
  font-size: 1.4rem;
  @media screen and (min-width: 720px) {
    font-size: 1.6rem;
  }
  @media screen and (min-width: 1000px) {
    font-size: 1.8rem;
  }
  margin-right: 1.3rem;
  text-decoration: none;
  &:hover {
    color: darkslategrey;
  }
  &.activeLink {
    border-bottom: 2px solid #666;
    color: darkslategrey;
  }
`

const Header = ({ active }) => {  
  return (
    <Nav>
      {/* <h3>Maaike Leyn</h3> */}
      <NavList navVisible={true}>
      <li>
          <NavLink to='/podcast' className={active === "podcast" ? "activeLink": ''}>
            Podcast
          </NavLink>
        </li>
        <li>
          <NavLink to='/dogs' className={active === "cat" ? "activeLink": ''}>
            Drawings
          </NavLink>
        </li>
        <li>
          <NavLink to='/' className={active === "news" ? "activeLink": ''}>
            News
          </NavLink>
        </li>
        <li>
          <NavLink to='/bio' className={active === "cv" ? "activeLink": ''}>
            Bio
          </NavLink>
        </li>
        {/* <li>
          <NavLink to='/about' className={active === "about" ? "activeLink": ''}>
            About
          </NavLink>
        </li> */}
        <li>
          <NavLink to='/contact' className={active === "contact" ? "activeLink": ''}>
            Contact
          </NavLink>
        </li>
      </NavList>
    </Nav>
  )
}


export default Header
