import React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/layout'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { StaticImage } from 'gatsby-plugin-image'

// import aflevering1 from '../audio/Aflv 1 - Duister gedoe - PREVIEW.mp3'

const StyleSheet = styled.div`
  color: #666;
`

const Title = styled.div`
  & > h2 {
    font-size: 22px;
    margin-bottom: 40px;
    color: darkslategrey;
  }
  & > h4 {
    // margin-top: 0px;
  }
  & > p {
    line-height: 1.2;
  }
  margin-bottom: 60px;
`

const Aflevering = styled.div`
  & > h4 {
    font-size: 18px;
    color: darkred;
  }
  margin-bottom: 60px;
`

const Description = styled.p`
  // margin-top: 0px;
  line-height: 1.2;
`

const Colofon = styled.div`
    margin-top: 100px;
    background-color: #eee;
    padding: 20px 20px;
    border-radius: 20px;
    & > h4 {
      margin-top: 0px;
    }
}
`

const Credits = styled.dl`
  margin: 0;
  & dt {
    display: inline-block;
    font-weight: bold;
  }
  & dt::after {
    content: ":";
  }
  & dd {
    display: inline;
    margin-left: 8px;
  }
  & dd::after {
    display: block;
    content: '';
  }
`

const Logos = styled.div`
  margin: 60px 0;
  & > div {
    max-width: 120px;
    margin-right: 30px;
  }
`

const EpkPage = ({ data }) => {
  return (
    <div>
      <Layout>
        <StyleSheet>
          <Title>
            <h2>Het Lilian-eum</h2>
            <p>Mijn schoonvader nodigde Liliane Vertessen begin jaren tachtig uit om een kunstwerk te installeren bij hem thuis. En dit in een ruimte naast zijn kabinet van psychiater. Het werd een Lilian-eum, een erotische kamer waarin kunstenares Liliane Vertessen zichzelf voordeed als vrouwelijk lustobject.</p>
            <p>Na de dood van mijn schoonvader belandt het Lilian-eum in mijn bureau. Maar wat moet ik er mee? Is dit werk - veertig jaar later - nog steeds relevant? Ik ga op onderzoek en besluit de kamer te renoveren, lukt het om dit kunstwerk terug tentoon te stellen?</p>
            <p>Ik zoek ook contact met Liliane. Ze leeft nog maar is gesteld op haar privacy. Is ze bereid om mee te werken aan deze podcast?</p>
            <p>Tal van ontmoetingen brengen me dichter bij Liliane, haar werk, de kunstwereld nu en in de jaren tachtig. Vertessens eigenzinnig, integer werk gaat over soevereiniteit: Liliane schikt zich niet naar de maatschappelijke of artistieke verwachtingen en doet geen concessies.</p>
            <p>Maar de podcast is ook een breder verhaal over de nog katholieke maatschappij en de eerder masculine kunstwereld van de jaren tachtig. Door terug te blikken ontstaat een reflectie op vandaag. </p>
            {/* <Aflevering> */}
              {/* <iframe style={{borderRadius:12}} src="https://open.spotify.com/embed/episode/33Mh6pNxriG2tMN7TZEHNg?utm_source=generator" width="100%" height="232" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe> */}
            {/* </Aflevering> */}
          </Title>

          <h2>Preview-afleveringen van de podcast-reeks:</h2>
          {data.allFile.edges.map(({ node }) => {
            console.log('node', node)
            const { childrenMarkdownRemark } = node // data.markdownRemark holds our post data
            const { frontmatter, rawMarkdownBody } = childrenMarkdownRemark[0]
            return (            
              <Aflevering>
                <h4>{frontmatter.title}</h4>
                <audio controls>
                  <source src={frontmatter.audiolink} />
                </audio>
                <Description>
                  <ReactMarkdown 
                      children={rawMarkdownBody}
                    />
                </Description>
              </Aflevering>
            )
          })}

          <Colofon>
            <h4>Een podcast door Maaike Leyn</h4>
            <Credits>
              <dt>Productie</dt><dd>Maaike Leyn</dd>
              <dt>Credits</dt><dd>Stijn Demeulenaere (audio-dramaturgie), Sam Serruys en Viktor De Greef (studio-werking De Grote Post)</dd>
              <dt>In samenwerking met</dt><dd>De Grote Post, Mu.Zee</dd>
              <dt>Met de steun van</dt><dd>de Vlaamse Gemeenschap, Sabam for culture</dd>
              <dt>Web-ondersteuning</dt><dd>Emmanuel Isebaert</dd>
              <dt>Fotografie</dt><dd>Giannina Urmeneta Ottiker</dd>
              <dt>Met dank aan</dt><dd>Liv Laveyne, Pascal Lervant, Mieke Mels, het vrt-archief, Kiem-festival, Chambres d'O, test-luisteraars (Daniella, Karlijn, Emmanuel, Karlien, Giannina, Annouk, ...)</dd>
              <dt>Een bijzondere dank u gaat naar alle interviewgasten</dt><dd>Phyline Deldycke, Anais Isebaert, Emmanuel Isebaert, Bart Debaere, Mieke Mels, Anny T'jampens,  Paul en Marie-Rose De Clercq, Francis De Beir, Philip Vandenberghe, Tom Nys, Leo Copers, Ria Pacquée, Elke Helbig (Zwart Huis), Bob Coppens, Tijs Lammar (Tick tack), Alain Verleysen, Frank Henderickx,  Dirk De Wachter, Rik Sadet, Bieke Demeester, Neon Elite ...</dd>
            </Credits>            
          </Colofon>

          <Logos>
            <StaticImage src='../images/vlaanderen.png' />
            <StaticImage src='../images/sabam.png' />
          </Logos>

        </StyleSheet>
      </Layout>
    </div>
  )
}

export default EpkPage

export const pageQuery = graphql`
  query MyQuery {
    allFile(filter: {relativeDirectory: {eq: "podcast"}}, sort: {fields: childrenMarkdownRemark___frontmatter___order, order: ASC}) {
      edges {
        node {
          childrenMarkdownRemark {
            rawMarkdownBody
            frontmatter {
              audiolink
              title
            }
          }
        }
      }
    }
  }
`
