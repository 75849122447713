import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import styled from 'styled-components'
import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

// const GlobalStyle = createGlobalStyle`
//   ${reset};
//   body {
//     font-family: 'Jaldi', sans-serif;
//     font-weight: 400;
//   }
// `

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Jaldi', sans-serif;
    font-weight: 400;
    @media screen and (min-width: 720px) {
      font-size: 1.1rem;
    }
    @media screen and (min-width: 1000px) {
      font-size: 1.2rem;
    }
  }
`

const Container = styled.div`
  margin: 0 auto;
  max-width: 750px;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Content = styled.div`
  flex-grow: 1;
`

const Layout = ({active, children}) => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <Container>
        <Content>
          <Header active={active} />
          {children}
        </Content>
        <Footer />
      </Container>
    </React.Fragment>
  )
}

export default Layout