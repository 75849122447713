import React from 'react'
import styled from 'styled-components'

const StyledFooter = styled.div`
  margin-top: 2rem;
  font-size: 0.8rem;
  color: #999;
`

const Footer = () => {
  return (
    <StyledFooter>
      Created by studio bubus, © 2023
    </StyledFooter>
  )
}

export default Footer
